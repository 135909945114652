export const Bio = {
    name: "Richard Castaneda",
    roles: [
      "NLP Data Analyst ",
      "Account Manager",
      "Web Developer",
      "ML Specialist",
    ],
    description:
      "I bring a unique blend of expertise in biological science, predictive forecasting, and machine learning optimization to the table. Proficient in an array of tools such as R, Python, Tableau, and Big Data frameworks like Hadoop, my skill set is both broad and deep. I thrive in data-intensive environments, consistently exceeding annual performance targets. With a passion for driving business growth and making strategic decisions, I am committed to expanding my analytics skill set. My ultimate goal is to contribute to an innovative team, tackle challenging problems, and elevate business performance through data-driven methodologies",
    github: "https://github.com/richard-castaneda",
    resume:
      "https://github.com/richard-castaneda/Resume/blob/main/Resume%202024.pdf",
    linkedin: "https://www.linkedin.com/in/r-castaneda/",
    medium: "https://medium.com/@richard-castaneda",
    twitter: "https://twitter.com/RichDataLab",
    leetcode: "https://leetcode.com/richardcastaneda/",
  };
  
  export const skills = [
    {
      title: "Data Analytics",   
      skills: [
        {
          name: "Python",
          image: "https://raw.githubusercontent.com/richard-castaneda/Pics/main/kisspng-python-javascript-logo-clip-art-soloist-5b52da8509ef39.3275845315321565490407.png",
        },
        {
          name: "R-Studio",
          image: "https://raw.githubusercontent.com/richard-castaneda/Pics/main/rstudio-macos-clip-art-r-b7e32b388316d82fb4ae059e9b8da376.png?token=GHSAT0AAAAAACFVFUCJMTVBYY4C6C4VWWAUZHPWQJA",
        },
        {
          name: "Microsoft Power BI",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/power.png?token=GHSAT0AAAAAACFVFUCJ47FXXHCKVEXBTW5QZHPWNEQ",
        },
        {
          name: "Microsoft Excel",
          image: "https://raw.githubusercontent.com/richard-castaneda/Pics/main/Microsoft%20Excel.png?token=GHSAT0AAAAAACFVFUCIWLCJMRCQ2DLWKDPYZHPWLDQ",
        },
        {
          name: "MATLAB",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/MATLAB.png?token=GHSAT0AAAAAACFVFUCIX3RN6G5LD6S53CWOZHPWKSQ",
        },
        {
          name: "Jupyter Notebook",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/Jupyter.png?token=GHSAT0AAAAAACFVFUCILL27OCBJ3Q6YODKGZHPWJTA",
        },
      ],
    },
    {
      title: "Big Data Tools",
      skills: [
        {
          name: "Tableau",
          image: "https://raw.githubusercontent.com/richard-castaneda/Pics/main/Tableau-Symbol.png?token=GHSAT0AAAAAACFVFUCJTJK22VB7TWL6MS3GZHPWWAA",
        },
        {
          name: "Google Cloud Platform",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/Google.png?token=GHSAT0AAAAAACFVFUCJNOFT6TVUJNTX4WE4ZHPWWWQ",
        },
        {
          name: "Apache Hive",
          image: "https://raw.githubusercontent.com/richard-castaneda/Pics/main/Apache%20Hive.png?token=GHSAT0AAAAAACFVFUCIH4D5RCI7II44M3HQZHPW5HQ",
        },
        {
          name: "Apache Spark ",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/Spark.png?token=GHSAT0AAAAAACFVFUCJ6WMM6DLFRRHS2XAGZHPW7UA",
        },
        {
          name: "MySQL",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/MySQL-Logo.png?token=GHSAT0AAAAAACFVFUCJ3XROK7QPR5TDAV66ZHPW6IA",
        },
        {
          name: "Amazon Web Services",
          image: "https://raw.githubusercontent.com/richard-castaneda/Pics/main/AWS.png?token=GHSAT0AAAAAACFVFUCIIDFSP64PX35MTN6OZHPW5VA",
        },
      ],
    },
    {
      title: "Machine Learning",
      skills: [
        {
          name: "TensorFlow",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/Tensorflow_logo.svg.png?token=GHSAT0AAAAAACFVFUCI5ZVRKFPXQXJIXVKEZHPXMRQ",
        },
        {
          name: "Scikit-learn",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/Scikit_learn.png?token=GHSAT0AAAAAACFVFUCJX6O2GGRQ4T3OPVKKZHPXM6A",
        },
        {
          name: "PyTorch",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/PyTorch_logo.png?token=GHSAT0AAAAAACFVFUCJ4CDGCPGVUZNH5EESZHPXNOA",
        },
        {
            name: "Anaconda",
            image:
                "https://raw.githubusercontent.com/richard-castaneda/Pics/main/Anaconda_Logo.png?token=GHSAT0AAAAAACFVFUCJJ54E6PM6KKES3PUIZHPXL6Q",
        },
        {
          name: "Keras",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/keras_logo.png?token=GHSAT0AAAAAACFVFUCJZNLPC4GGZS74HZJGZHPXQ3A",
        },
        {
          name: "Hugging Face",
          image:
                "https://raw.githubusercontent.com/richard-castaneda/Pics/main/hf-logo.png?token=GHSAT0AAAAAACFVFUCIOC5T34YGA2L7UGMWZHPXRPQ",
        },
      ],
    },
    {
      title: "Others",
      skills: [
        {
          name: "Git",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/git.png",
        },
        {
          name: "GitHub",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/newgithub.png",
        },
        {
          name: "Docker",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/docker.png",
        },
        {
          name: "Miro",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/Miro.png",
        },
        {
          name: "Blender",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/Blender.png",
        },
        {
          name: "Affinity Designer",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/AF%20Des.png",
        },
        {
          name: "VS Code",
          image:
            "https://raw.githubusercontent.com/richard-castaneda/Pics/main/VSCode.png",
        },
      ],
    },
  ];
  
  export const experiences = [
    {
      id: 1,
      img: "https://raw.githubusercontent.com/richard-castaneda/Python_Animations/main/Screenshot%202024-01-13%20233420.png",
      role: "NLP Data Analyst",
      company: "Reclaiming Each Climb",
      date: "Jan 2022 - Present",
      desc: "My responsibilities include developing NLP models to analyze text from over 1,000 beneficiaries, enhancing aid allocation. Conducting sentiment analysis on surveys from 12,000+ students has informed a 25% increase in support services. Leadership in data-driven initiatives contributed to a 30% increase in donor contributions. Additionally, streamlined data workflows resulted in a 40% reduction in processing time while maintaining accuracy. These efforts have significantly improved organizational effectiveness and efficiency.",
      skills: [
        "Natural Language Processing (NLP)",
        "Machine Learning",
        "Data Analysis",
        "Python",
        "TensorFlow",
        "Sentiment Analysis",
        "Tableau",
        "PowerBI",
        "Statistical Analysis",
        "SQL",
        "Project Management",
      ],
    },
    {
      id: 2,
      img: "https://raw.githubusercontent.com/richard-castaneda/Pics/main/spaces-logo.png?token=GHSAT0AAAAAACFVFUCJCQTEQWCY3Q646XIIZHPRXAQ",
      role: "Account Manager",
      company: "Spaces",
      date: "Apr 2018 - Dec 2022",
      desc: "Delivered comprehensive office solutions to 3,000+ global locations and managed high-value accounts exceeding $1M annually. Achieved sales targets over $8M through portfolio management and drove 140% YoY sales growth via Genesys platform optimization.",
      skills: [
        "Dynamics 365",
        "Power BI",
        "Geneyes",
        "Customer Relationship Management",
        "Analytical Skills",
        "Data-Driven Decision Making",
        "Project Strategy & Planning",
      ],
    },
    {
      id: 3,
      img: "https://raw.githubusercontent.com/richard-castaneda/Python_Animations/main/Grainger.png",
      role: "Assistant Manager",
      company: "The Woodlark Companies",
      date: "Jan 2016 - Apr 2018",
      desc: "In my property management role, I successfully maximized rental income while cutting costs. I achieved a 98% occupancy rate through targeted marketing and maintained fiscal discipline by managing annual budgets effectively. My proactive strategies in collections significantly reduced overdue payments and churn rates, striking a balance between revenue growth and customer satisfaction.",
      skills: [
        "Dynamics 365",
        "Yardi",
        "AppFolio",
        "Cost Reduction Strategies",
        "Marketing and Advertising",
        "Tenant and Lease Management",,
        "Team Leadership and Training",
        "Analysis for Property Performance",
      ],
    },
    {
      id: 4,
      img: "https://raw.githubusercontent.com/richard-castaneda/Pics/main/utsa%20final.png?token=GHSAT0AAAAAACFVFUCJT73CS2JHPHDLJOL6ZHPSYKQ",
      role: "Supplemental Instructor",
      company: "University of Texas at San Antonio",
      date: "Aug 2013 - Dec 2015",
      desc: "In my role as a Supplemental Instructor, I captivated students with compelling lectures in Computer Science, Physics, and Biology, boosting class attendance. I kept the curriculum fresh and relevant through consistent updates, enhancing educational impact. My collaboration with faculty led to more engaging and varied lectures, increasing student involvement. By conducting interactive sessions, I tailored my teaching approach to diverse learning styles, enhancing comprehension across the board.",
      skills: [
        "MATLAB",
        "Public Speaking and Presentation Skills",
        "Student Engagement Techniques",
        "Educational Technology Utilization",
        "Critical Thinking and Problem-Solving",
        "Curriculum Development",
      ],
    },
  ];
  
  export const education = [
    {
      id: 0,
      img: "https://raw.githubusercontent.com/richard-castaneda/Pics/main/UNT.png",
      school: "University of North Texas",
      date: "Aug 2022 - Dec 2023",
      grade: "4.0 GPA",
      desc: "Actively seeking opportunities where I can apply my skills in data analytics to solve real-world challenges. My academic journey has been marked by a strong focus on practical applications of data science, particularly in areas of Natural Language Processing and Machine Learning.",
      degree: "Advanced Data Analytics, Master of Science",
    },
    {
      id: 1,
      img: "https://raw.githubusercontent.com/richard-castaneda/Pics/main/utsa%20final.png?token=GHSAT0AAAAAACFVFUCJT73CS2JHPHDLJOL6ZHPSYKQ",
      school: "Unviersity of Texas at San Antonio",
      date: "August 2012 - Dec 2016",
      grade: "3.78 GPA",
      desc: "Specialized in leveraging data science techniques to analyze complex biological data sets. Involved in projects where I applied statistical models and data visualization tools to interpret trends in medical biology, with MATLAB. Member of the Tri Beta National Honor Society, recognizing academic excellence.",
      degree: "Medical Biology, Bachelor of Science",
    },
  ];
  
  export const projects = [
    {
      id: 0,
      title: "Semantics Peak",
      date: "Ongoing..",
      description:
      "Semantics Peak is an advanced web application leveraging state-of-the-art techniques in machine learning to address the challenge of catastrophic forgetting in neural networks. This project showcases the implementation of Elastic Weight Consolidation (EWC) to preserve learning across multiple tasks without interference. It also explores Dynamic Neural Networks for adaptable, context-sensitive learning architectures, and employs Experience Replay to effectively integrate new knowledge while retaining old information. These techniques are crucial in creating a robust, continuously learning system that evolves without losing prior knowledge, making Semantics Peak a pioneering effort in the realm of sustainable AI development. The application's intuitive interface and backend architecture, developed using React and Node.js, make it a powerful tool for both machine learning enthusiasts and professionals.",
      image:
      "https://raw.githubusercontent.com/richard-castaneda/SemanticsPeakSite/main/Semantics%20Peak.png",
      tags: [
        "Python",
        "TensorFlow",
        "PyTorch",
        "Keras",
        "Node.js",
        "MongoDB",
        "Redux",
        "React Js",
        "Docker",
        "AWS",
        "Eslint",
        "Husky",
      ],
      category: "Passion Project",
      github: "https://github.com/richard-castaneda/Python_Animations",
      webapp: "https://github.com/richard-castaneda/Python_Animations",
      member: [
        {
          name: "Richard Castaneda",
          img: "https://avatars.githubusercontent.com/u/125170916?v=4",
          linkedin: "https://www.linkedin.com/in/r-castaneda/",
          github: "https://github.com/richard-castaneda",
        }
      ] 
    },
    {
      id: 1,
      title: "Google Dialogflow CX",
      date: "Jan 2023 - May 2023",
      description:
      "Designed and implemented an AI chatbot for a coffee shop using Google Dialogflow CX, enhancing customer service and order efficiency. The bot is programmed to handle a variety of customer interactions, from menu inquiries to order placement. Integrated with a full-stack web application, ensuring seamless interaction across various platforms. Employed Google Auth for secure user authentication and React JS for a responsive interface, ensuring a smooth user experience. Agile methodologies were utilized to streamline development and foster effective team collaboration.",
    image:
      "https://raw.githubusercontent.com/richard-castaneda/Python_Animations/main/Screenshot%202024-01-04%20011221.png",
    tags: ["Google Dialogflow CX", "Natural Language Processing", "CI/CD", "Google Auth", "Agile Methodologies"],
      category: "machine learning",
      github: "https://github.com/richard-castaneda",
      webapp: "https://github.com/richard-castaneda",
      member: [
        {
          name: "Richard Castaneda",
          img: "https://avatars.githubusercontent.com/u/125170916?v=4",
          linkedin: "https://www.linkedin.com/in/r-castaneda/",
          github: "https://github.com/richard-castaneda",
        },
      ],
    },
    {
      id: 2,
      title: "Vexa",
      date: "Oct 2022 - Present",
      description:
        "Designed and developed the Vexa project, a project management app that helps users and teams stay organized and on track. Implemented key features such as task tracking, team collaboration, and time tracking to improve productivity and project outcomes and also invite team/project members. The plan is to add a community of users where they can find developers and other team members and chat system implementation.",
      image:
        "https://user-images.githubusercontent.com/64485885/234916413-96296f13-fe4b-4cc4-b215-e72bd7c27928.png",
      tags: [
        "React Js",
        "MongoDb",
        "Node Js",
        "Express Js",
        "Redux",
        "NodeMailer",
      ],
      category: "data analytics",
      github: "https://github.com/richard-castaneda/Python_Animations",
      webapp: "https://github.com/richard-castaneda/Python_Animations",
      member: [
        {
          name: "Richard Castaneda",
          img: "https://avatars.githubusercontent.com/u/125170916?v=4",
          linkedin: "https://www.linkedin.com/in/r-castaneda/",
          github: "https://github.com/richard-castaneda",
        },
      ],
    },
    {
      id: 4,
      title: "Louisville Metro Crime",
      date: "Jan 2022 - Jun 2022",
      description:
        "The project examines crime rates and public safety funding in Louisville, by reviewing historical patterns and financial strategies for aiding crime victims. Two key datasets: the Louisville Metro Crime Rates dataset, comprising over 300,000 records detailing various crime incidents, and the Louisville’s Funds Report dataset, which provides insights into fiscal allocations for victim support. These datasets, collected at specific dates, offer an unaltered, comprehensive view of the city's crime landscape and its financial response, contributing to a broader understanding of public safety challenges at both local and national levels.",
      image:
        "https://raw.githubusercontent.com/richard-castaneda/CAPSTONE/main/Screenshot%202024-01-29%20130545.png",
      tags: ["Data Analytics", "Python", "Chi-Square", "GIS Clustering", 'Literature Review'],
      category: "data analytics",
      github: "https://github.com/richard-castaneda/CAPSTONE",
      webapp: "https://github.com/richard-castaneda/CAPSTONE",
      member: [
        {
          name: "Richard Castaneda",
          img: "https://avatars.githubusercontent.com/u/125170916?v=4",
          linkedin: "https://www.linkedin.com/in/r-castaneda/",
          github: "https://github.com/richard-castaneda",
        },
      ],
    },
    {
      id: 7,
      title: "Stock Portfolio Dashboard",
      date: "May 2023 - August 2023",
      description:
        "Dynamic Tableau Dashboard to review stock prices of the top 10 industries. P/E ratio, Volume, Market Cap.",
      image:
        "https://raw.githubusercontent.com/richard-castaneda/ADTA-5250-DV/main/Screenshot%202024-01-29%20133431.png",
      tags: ["Tableau", "Stocks", "Data Engineering", "Investor Presentation", "API", "Data Viz"],
      
      category: "data analytics",
      github: "https://github.com/richard-castaneda/ADTA-5250-DV",
      webapp: "https://github.com/richard-castaneda/ADTA-5250-DV",
      member: [
        {
          name: "Richard Castaneda",
          img: "https://avatars.githubusercontent.com/u/125170916?v=4",
          linkedin: "https://www.linkedin.com/in/r-castaneda/",
          github: "https://github.com/richard-castaneda",
        },
      ],
    },
    {
      id: 5,
      title: "LSTM Time-Series Analysis",
      date: "Jan 2021",
      description:
        "An in-depth project exploring LSTM neural networks for predicting Tesla stock prices using historical data. It encompasses data normalization, complex LSTM architecture, and extensive data analysis for accurate stock trend predictions.",
      image:
        "https://raw.githubusercontent.com/richard-castaneda/ADTA-5560/main/Screenshot%202024-01-29%20120818.png",
      tags: ["LSTM", "Time-Series Data", "Stock Prediction", "Neural Networks", "Data Normalization", "Python"],
      category: "machine learning",
      github: "https://github.com/richard-castaneda/ADTA-5560/tree/main",
      webapp: "https://github.com/richard-castaneda/ADTA-5560/tree/main",
      member: [
        {
          name: "Richard Castaneda",
          img: "https://avatars.githubusercontent.com/u/125170916?v=4",
          linkedin: "https://www.linkedin.com/in/r-castaneda/",
          github: "https://github.com/richard-castaneda",
        },
      ],
    },
  ];
  
  export const TimeLineData = [
    { year: 2016, text: "Biology B.S." },
    { year: 2018, text: "Moved" },
    { year: 2019, text: "Work" },
    { year: 2022, text: "Switch Careers" },
    { year: 2023, text: "ADTA M.S." },
  ];