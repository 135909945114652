import styled from 'styled-components';
import { Bio } from '../../data/constants';

const mediumIconURL = 'https://raw.githubusercontent.com/richard-castaneda/Pics/main/medium.png';
const leetCodeIconURL = 'https://raw.githubusercontent.com/richard-castaneda/Pics/main/LeetCode.png';
const companyXIconURL = 'https://raw.githubusercontent.com/richard-castaneda/Pics/main/X.png';
const customLinkedInIconURL = 'https://raw.githubusercontent.com/richard-castaneda/Pics/main/In.png';


const FooterContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  //background: linear-gradient(100.26deg, rgba(0, 102, 255, 0.05) 42.33%, rgba(150, 0, 225, 0.05) 127.07%);
`;


const FooterWrapper = styled.footer`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  padding: 1rem;
  color: ${({ theme }) => theme.text_primary};
`;

const Logo = styled.h1`
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => theme.primary};
`;

const Nav = styled.nav`
  width: 100%;
  max-width: 800px;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
`;

const NavLink = styled.a`
color: ${({ theme }) => theme.text_primary};
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const SocialMediaIcons = styled.div`
  display: flex;
  margin-top: 1rem;
`;

const SocialMediaIcon = styled.a`
  display: inline-block;
  margin: 0 1rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const Copyright = styled.p`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.soft2};
  text-align: center;
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterWrapper>
        <Logo>Richard Castaneda</Logo>
        <Nav>
          <NavLink href="#about">About</NavLink>
          <NavLink href="#skills">Skills</NavLink>
          <NavLink href="#experience">Experience</NavLink>
          <NavLink href="#projects">Projects</NavLink>
          <NavLink href="#education">Education</NavLink>
        </Nav>
        <SocialMediaIcons>
          <SocialMediaIcon href="https://medium.com/@richard-castaneda" target="display">
            <img src={mediumIconURL} alt="Medium" style={{ width: '24px', height: '24px' }} />
          </SocialMediaIcon>
          <SocialMediaIcon href="https://leetcode.com/richardcastaneda/" target="display">
            <img src={leetCodeIconURL} alt="LeetCode" style={{ width: '24px', height: '24px' }} />
          </SocialMediaIcon>
          <SocialMediaIcon href="https://twitter.com/richdatalabs" target="display">
            <img src={companyXIconURL} alt="Company X" style={{ width: '24px', height: '24px' }} />
          </SocialMediaIcon>
          <SocialMediaIcon href="https://www.linkedin.com/in/r-castaneda/" target="display">
            <img src={customLinkedInIconURL} alt="LinkedIn" style={{ width: '24px', height: '24px' }} />
          </SocialMediaIcon>
        </SocialMediaIcons>
        <Copyright>
          &copy; 2024 Richard Castaneda. All rights reserved.
        </Copyright>

      </FooterWrapper>
    </FooterContainer>
  );
}

export default Footer;